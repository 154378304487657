/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { jsx, css } from '@emotion/core';
import SEO from '../components/seo';
import PageContainer from '../components/page-container';
import Title from '../components/title';
import Content from '../components/content';

const classCardTitle = css`
  ${tw`uppercase font-light text-2xl text-red mt-0 mb-4 text-center`};
`;

const ClassCard = props => {
  const { title, description, price, items } = props;

  return (
    <div css={tw`shadow p-3 mb-4`}>
      <h2 css={classCardTitle}>{title}</h2>
      <ul css={tw`list-reset font-medium`}>
        <li css={tw`p-0 mb-4 font-normal`}>{description}</li>
        {items.map((item, v) => (
          <li css={tw`pl-3 mb-2 leading-normal`} key={v}>
            {item}
          </li>
        ))}
        <li css={tw`pl-3 mt-4`}>{price}</li>
      </ul>
    </div>
  );
};

ClassCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  items: PropTypes.array,
};

const ClassesPage = ({ data }) => {
  const { frontmatter: fm } = data.page;
  const { html: content } = data.page;
  return (
    <PageContainer>
      <SEO title={fm.title} description={fm.metaDescription} />
      <div className="section">
        <Title text={fm.title} />
        <div className="columns">
          <div className="column is-two-thirds">
            <Content html={content} />
          </div>
          <div className="column">
            <ClassCard
              title={fm.classes.intro.title}
              description={fm.classes.intro.description}
              price={fm.classes.intro.price}
              items={fm.classes.intro.cardItems}
            />
            <div>
              <Img
                fluid={data.classImage.childImageSharp.fluid}
                css={tw`rounded`}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

ClassesPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        metaDescription: PropTypes.string,
        classes: PropTypes.shape({
          intro: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            price: PropTypes.string,
            cardItems: PropTypes.array,
          }),
        }),
      }),
    }),
  }),
};

export default ClassesPage;

export const query = graphql`
  query ClassesPageQuery {
    page: markdownRemark(frontmatter: { title: { eq: "Classes" } }) {
      html
      frontmatter {
        title
        metaDescription
        classes {
          intro {
            title
            description
            price
            cardItems
          }
        }
      }
    }
    classImage: file(relativePath: { eq: "images/classes/class-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
